<template lang="pug">
  .benefits
    .benefits__container
      h2.title.benefits__title
        | ПРО DRIVELINK
      .benefits__text
        p
          | Тепер бути онлайн стало ще простіше завдяки модифікованій антені Starlink.
          br
          | Отримайте швидкий і стабільний Інтернет в дорозі під час подорожі або відпочинку за містом, де недоступні інші точки доступу інтернету.
        p Замовте вже готове рішення або передайте Ваш термінал для модифікації у зручний засіб комунікації будь-де та будь-коли.
      ul.benefits__list
        li(
          v-for="(item, index) in benefitsItems"
          :key="index"
        ).benefits__item
          .benefits__img
            img(
              :src="item.imageSrc"
              :alt="item.title"
            )
          h3.benefits__subtitle {{ item.title }}
          .text.benefits__desc
            p {{ item.description }}
</template>

<script>
import Benefits1 from '@/assets/images/benefits/01.svg'
import Benefits2 from '@/assets/images/benefits/02.svg'
import Benefits3 from '@/assets/images/benefits/03.svg'
import Benefits4 from '@/assets/images/benefits/04.svg'

export default {
  name: 'BenefitsBlock',
  data () {
    return {
      benefitsItems: [
        {
          imageSrc: Benefits1,
          title: 'Легкість використання системи',
          description:
            'Завдяки особливостям будови корпусу, Drivelink завжди готовий до використання без зайвих налаштувань. Він надійно кріпиться за допомогою магнітів до горизонтальної поверхні транспортного засобу: на дах або капот автомобіля, передаючи сигнал на Wi-Fi роутер всередину. Аеродинамічна конструкція не впливає на швидкість руху авто і не обмежує його маневреність.'
        },
        {
          imageSrc: Benefits2,
          title: 'Швидкість передачі даних',
          description:
            'Приймач супутникового інтернету Starlink зберігає заявлену виробником швидкість зʼєднання (до 150 Мбіт/сек) з мінімальним пінгом (до 70 мс). Показники залишаються на високому рівні навіть при збільшенні швидкості руху автомобіля. Невеликі втрати якості з\'єднання можливі у звʼязку з погодними умовами або фізичними перешкодами.'
        },
        {
          imageSrc: Benefits3,
          title: 'Простота підключення терміналу',
          description:
            'Пристрій встановлюється на горизонтальну поверхню автомобіля та зʼєднується кабелем із роутером, розташованим всередині машини. Wi-Fi роутер під час руху може живитись від інвертора, підключеного до прикурювача автомобіля на 12V, що забезпечує безперервний зв\'язок. При тривалій зупинці, наприклад, ночівлі, Ви можете використовувати генератор або зарядну станцію.'
        },
        {
          imageSrc: Benefits4,
          title: 'Доступність безперебійного звʼязку',
          description:
            'Завдяки мережі космічних супутників отримайте постійне високошвидкісне інтернет-підключення «на колесах», що пересувається разом з вами. Термінали Starlink високоефективні в районах, де недоступні дротовий інтернет або вишки мобільних операторів, чи в регіонах з перебоями в електропостачанні.'
        }
      ]
    }
  }
}
</script>
